var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { rounded: "lg" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-alert",
            {
              attrs: {
                color: "primary darken-2",
                border: "top",
                outlined: "",
                icon: "mdi-alert",
                prominent: ""
              }
            },
            [
              _c("h3", [
                _vm._v("Your registration link has expired or is invalid.")
              ]),
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  " Please check your email and try again or request another registration invitation. "
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }