<template>
    <v-card rounded="lg">
        <v-card-text>
            <v-alert
                color="primary darken-2"
                border="top"
                outlined
                icon="mdi-alert"
                prominent
            >
                <h3>Your registration link has expired or is invalid.</h3>
                <p class="mt-4">
                    Please check your email and try again or request another
                    registration invitation.
                </p>
            </v-alert>
        </v-card-text>
    </v-card>
</template>


<script>
export default {
    name: "register-error",
};
</script>

<style lang="scss" scoped>
</style>